import request from "@/utils/request";
import {downloadFile,knowDownloadFile} from "@/httpsAPI/common"

export function getKnowList(params) {
  return request({
    url: "/wiki/listWiki",
    method: 'post',
    data: params
  })
}

export function getDeclareDetail(params) {
  return request({
    url: "/policy/PolicyType/detail/" + params,
    method: 'get',
    data: {}
  })
}

//新建
export function addKnow(params) {
  return request({
    url: "/wiki/addWiki",
    method: 'post',
    data: params
  })
}

//收藏
export function collectKnow(params) {
  return request({
    url: "/wiki/wikiCollect/batchWikiCollect",
    method: 'post',
    data: params
  })
}

//取消收藏
export function delCollectKnow(params) {
  return request({
    url: "/wiki/wikiCollect/deleteWikiCollect",
    method: 'post',
    data: params
  })
}

//删除我的知识库
export function delKnowList(params) {
  return request({
    url: "/wiki/deleteWiki",
    method: 'post',
    data: params
  })
}

//编辑我的知识库
export function updateKnowList(params) {
  return request({
    url: "/wiki/updateWiki",
    method: 'post',
    data: params
  })
}

//详情列表
export function detailKonwList(params) {
  return request({
    url: "/wiki/wikiDic/wikiDicList",
    method: 'post',
    data: params
  })
}
//详情列表新增
export function addDetailKonwList(params) {
  return request({
    url: "/wiki/wikiDic/add",
    method: 'post',
    data: params
  })
}
//详情列表修改
export function updateDetailKonwList(params) {
  return request({
    url: "/wiki/wikiDic/update",
    method: 'post',
    data: params
  })
}
//详情列表删除
export function delDetailKonwList(params) {
  return request({
    url: "/wiki/wikiDic/delete",
    method: 'post',
    data: params
  })
}
//详情列表
export function getDetailList(params) {
  return request({
    url: "/wiki/wikiDic/detail/" + params,
    method: 'get',
    data: {}
  })
}
//知识库分类
export function wikiType(params) {
  return request({
    url: "/wiki/wikiType/listAll",
    method: 'post',
    data: params
  })
}

export function downloadCommonFile(params) {
  return downloadFile('project/commonFile/file-download/'+ params.id, params.fileName, null, 'post')
}


// 知识库附件管理查询
export function getAccessoryFileList(parames) {
  return request({
      url: '/project/commonFile/list',
      method: 'post',
      data: parames
  })
}
// 删除知识库附件管理
export function deleteAccessoryFile(parames) {
  return request({
      url: '/project/commonFile/delete',
      method: 'post',
      data: parames
  })
}
// 公共知识库全部分类
export function getWikiTypeEntListAll(parames) {
  return request({
      url: '/wiki/wikiTypeEnt/listAll',
      method: 'post',
      data: parames
  })
}
// 公共知识库企业分类列表
export function getWikiTypeEntList(parames) {
  return request({
      url: '/wiki/wikiTypeEnt/list',
      method: 'post',
      data: parames
  })
}
// 删除公共知识库企业分类列表
export function delWikiTypeEntList(parames) {
  return request({
      url: '/wiki/wikiTypeEnt/delete',
      method: 'post',
      data: parames
  })
}
// 新增公共知识库企业分类列表
export function addWikiTypeEntList(parames) {
  return request({
      url: '/wiki/wikiTypeEnt/add',
      method: 'post',
      data: parames
  })
}
// 修改公共知识库企业分类列表
export function updateWikiTypeEntList(parames) {
  return request({
      url: '/wiki/wikiTypeEnt/update',
      method: 'post',
      data: parames
  })
}