<template>
  <a-modal
      title="新建目录"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        <a-form-item label="名称" prop="name">
          <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入名称' }] }]" />
        </a-form-item>
        
        <a-form-item label="排序" prop="sort">
          <a-input-number v-decorator="['sort']" />
        </a-form-item>

      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import { addDetailKonwList } from "@/httpsAPI/knowledgeBase/index";
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                wikiId: 0,
                parentId: 0,
                visible: false,
                confirmLoading: false
            }
        },
        methods:{
          dataFormSubmit(e) {
            this.confirmLoading = true;
            this.form.validateFields((err, values) => {
                if (err) {
                    console.log(err)
                    return;
                }
            
                values['wikiId'] = this.wikiId;
                values['parentId'] = this.parentId;
                addDetailKonwList(values).then(res=>{
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                }).catch(error=>{
                    console.log(error)
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (obj) {
            this.wikiId = obj.wikiId;
            this.parentId = obj.parentId;
            this.visible = true;

            this.$nextTick(() => {
                this.form.resetFields();
            });
          }
        }
    }
</script>