<template>
  <a-modal
      title="修改目录"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        <a-form-item label="名称" prop="name">
          <a-input v-decorator="['name', { initialValue:dataForm.name, rules: [{ required: true, message: '请输入名称' }] }]" />
        </a-form-item>
        
        <a-form-item label="排序" prop="sort">
          <a-input-number v-decorator="['sort', {initialValue:dataForm.sort}]" />
        </a-form-item>

      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import { updateDetailKonwList,getDetailList } from "@/httpsAPI/knowledgeBase/index";
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                dataForm: {
                  id: 0,
                  wikiId: 0,
                  parentId: 0,
                  name: '',
                  sort: 1,
                  content: '',
                },
                visible: false,
                confirmLoading: false
            }
        },
        methods:{
          dataFormSubmit(e) {
            this.confirmLoading = true;
            this.form.validateFields((err, values) => {
                if (err) {
                    console.log(err)
                    return;
                }

                values['id'] = this.dataForm.id;
                values['wikiId'] = this.dataForm.wikiId;
                values['parentId'] = this.dataForm.parentId;
                values['content'] = this.dataForm.content;
                updateDetailKonwList(values).then(res=>{
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                }).catch(error=>{
                    console.log(error)
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (id) {
            this.dataForm.id = id || 0
            this.visible = true
            this.$nextTick(() => {
                this.form.resetFields();

                if (this.dataForm.id) {
                  getDetailList(id).then(res=>{
                    console.log(res.data,"res.data")
                    this.dataForm.id = res.data.id;
                    this.dataForm.content = res.data.content;
                    this.dataForm.wikiId = res.data.wikiId;
                    this.dataForm.name = res.data.name;
                    this.dataForm.parentId = res.data.parentId;
                    this.dataForm.sort = res.data.sort
                  }).catch(error=>{
                      console.log(error)
                  })
                }
            })
          }
        }
    }
</script>